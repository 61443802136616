import React from 'react';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import NumberHelper from '../../../util/NumberHelper';
import DateHelper from '../../../util/DateHelper';
import { AppIcon, AppButton } from '../../../index';
import { LPOverridable } from '../../../index';

export default class TransactionInfoModal extends React.Component {

  render() {
    let transaction = this.props.transaction;
    let isFX = transaction.originalAmountCurrency && transaction.originalAmountCurrency !== transaction.amountCurrency;
    let amount = transaction.amount === 0 ? NumberHelper.currency(transaction.fee, transaction.amountCurrency) : NumberHelper.currency(transaction.amount, transaction.amountCurrency);

    let dataToShow = [

      {
        label: 'Type',
        className: 'transaction-type',
        value: transaction.typeReadable
      },
      {
        label: 'Status',
        className: 'transaction-status',
        value: transaction.statusReadable
      },
      {
        label: 'Transaction Date',
        className: 'transaction-date',
        value: DateHelper.date(transaction.postDate)
      },
      {
        label: 'Settlement Date',
        className: 'transaction-settlement-date',
        value: DateHelper.date(transaction.transactionDate)
      }
    ];

    let fee = parseFloat(transaction.fee);
    if (parseFloat(transaction.fee)) {
      if (isFX && transaction.status === 'settled') {
        fee = fee - parseFloat(transaction.fxPadding);
      }
    }

    if (transaction.amount === 0 && transaction.fee > 0) {
      fee = null;
    }

    if (isFX) {
      let rate = Math.abs(transaction.originalAmount / transaction.amount);
      dataToShow.push({
        label: 'Spent',
        className: 'transaction-spent',
        value: NumberHelper.currency(transaction.originalAmount, transaction.originalAmountCurrency)
      });
      dataToShow.push({
        label: 'FX rate',
        className: 'transaction-fx-rate',
        value: NumberHelper.currency(1, transaction.amountCurrency) + ' = ' + NumberHelper.currency(rate, transaction.originalAmountCurrency)
      });
      if (fee) {
        dataToShow.push({
          label: 'Fee',
          className: 'transaction-fee',
          value: NumberHelper.currency(parseFloat(transaction.fee) - parseFloat(transaction.fxPadding), transaction.amountCurrency)
        });
      }
    } else {
      if (fee) {
        dataToShow.push({
          label: 'Fee',
          className: 'transaction-fee',
          value: NumberHelper.currency(parseFloat(transaction.fee), transaction.amountCurrency)
        });
      }
    }

    dataToShow.push({
      label: 'Running balance',
      className: 'transaction-running-balance',
      value: NumberHelper.currency(transaction.runningBalance, transaction.amountCurrency)
    });

    let fullDate = DateHelper.fullDate(transaction.transactionDate);

    return (
      <ModalDialog
        id={'TRANSACTION_INFO'}
        show={true}
      >
        <div className={'transaction-modal ' + (transaction.status ? ('transaction-status-' + transaction.status) : '') }>

          <div className='close-btn'>
            <AppIcon name={'fa.times'} style={{ fontSize: 24, color: 'rgba(0,0,0,0.4)', cursor: 'pointer' }} onClick={() => ModalService.hideModal()} />
          </div>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <h4>{transaction.note}</h4>
            <span className='icon-holder'>
              <i className='fa fa-info' aria-hidden={true} style={{ color: 'white', fontSize: 31 }}></i>
            </span>
            <div>{fullDate}</div>
            <p className="tx-amount">{amount}</p>
            <div className="transaction-details">
              {dataToShow.map((x, index) => {
                return (
                  <div className={'row ' + (x.className ? x.className : '')} key={'transaction' + index}>
                    <div className="col-xs-6 detail-label">
                      {x.label}:
                  </div>
                    <div className="col-xs-6 detail-value">
                      {x.value}
                    </div>
                  </div>
                )
              })}

            </div>
          </div>
          <LPOverridable name={'transaction.details.modal.bottom'}>
            <div style={{ display: 'flex', flex: 0.5, alignItems: 'center', justifyContent: 'center' }}>
              <AppButton customstyle={{ maxWidth: 450, maxHeight: 60 }}>YOU DON’T RECOGNIZE THIS TRANSACTION?</AppButton>
            </div>
          </LPOverridable>
        </div>
      </ModalDialog>
    )
  }
}
