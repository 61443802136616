import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PublicLayout from './components/layout/PublicLayout';
import DefaultLayout from './components/layout/DefaultLayout';
import { connect } from 'react-redux';
import { appMountedAction } from './logic/store/AppActions';
import { refreshAuthAction } from './modules/auth/AuthActions';
import { AppRoute, AppRouter } from './components';
import Modals from './modules/modals/Modals';
import { OverrideService } from './logic/services';

class App extends Component<any, any> {

  componentDidMount() {
    this.props.appMounted();
    this.props.refreshAuth();

    // AuthService.poolStatusUpdate();
  }

  getInnerPaths(props: { match: { path: any; }; }) {

    // if(props.match.params && props.match.params.locale) {
    //   locale = props.match.params.locale;
    // }

    let url = props.match.path;
    url = url.replace(/\/$/, "");

    let customRoutes = OverrideService.getRoutes();

    return (
      <Switch>
        {customRoutes}
        <AppRoute path={`${url}/login`} component={PublicLayout}  unAuthenticatedOnly />
        <AppRoute path="/forgot-password" component={PublicLayout} unAuthenticatedOnly />
        <AppRoute path="/welcome" component={PublicLayout} unAuthenticatedOnly />
        <AppRoute path="" component={DefaultLayout} authenticatedOnly />
      </Switch>
    )
  }
  render() {
    if (!this.props.ready) return null;

    let InnerRouting = (props: any) => this.getInnerPaths(props);

    return (
        <AppRouter>
          <div className="App">
            <Switch>
              <Route path="/" component={InnerRouting}/>
            </Switch>
            <div id="modalHolder" className="modal-holder">
              <Modals/>
            </div>
          </div>
        </AppRouter>

    );
  }
}

const mapDispatchToProps = (dispatch: { (arg0: { type: string; payload: null; }): void; (arg0: { type: string; }): void; }) => {
  return {
    appMounted: () => {
      return dispatch(appMountedAction())
    },
    refreshAuth: () => {
      return dispatch(refreshAuthAction())
    }
  }
}

const mapStateToProps = (state: { ready: any; }) => {
  return {
    ready: state.ready
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
