import AppStore from '../logic/store/AppStore';

export function getCurrency() {
  const state = AppStore.getState();
  return state.auth.profile.currency;
}

export function getCurrencySymbol(currency = null) {
  if (!currency) {
    // eslint-disable-next-line no-param-reassign
    currency = getCurrency();
  }
  const map = {
    EUR: '€',
    USD: '$',
    GBP: '£'
  };

  return map[currency] || currency;
}

export default {
  getCurrency,
  getCurrencySymbol
};
