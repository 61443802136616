import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppProgressBar, AppIcon, LPOverridable } from '../../../index';
import CardService from '../../card/CardService';
import { currency } from '../../../util/NumberHelper';
import ModalService from '../../../logic/services/ModalService';
import { __ov } from '../../../logic/services/overrides';
import FXRatesModal from '../../fx-rates/FXRatesModal';
import OverrideService from '../../../logic/services/overrides/OverrideService';

const DashbaoardHeader = props => {
  const [modalVisible, setModalVisible] = useState(false);
  const { profile } = props;

  const paymentDevice = CardService.getDefaultCard(props.paymentDevices);
  if (!paymentDevice) return null;

  const cardActive = paymentDevice.isLive;
  // let cardActive = false;

  const percentage =
    parseFloat(paymentDevice.lastMaxBalance) &&
    parseFloat(paymentDevice.balance)
      ? (parseFloat(paymentDevice.balance) /
          parseFloat(paymentDevice.lastMaxBalance)) *
        100
      : 0;

  const loadAccount = (
    <div
      onClick={() => ModalService.showModal('LOAD_CARD_MODAL', { profile })}
      className="send-load-btn-wrapper"
    >
      {' '}
      <div className="send-load-icon-wrapper">
        {' '}
        <AppIcon
          name="fa.plus"
          style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
        />{' '}
      </div>
      Load
    </div>
  );

  const sendMoney = (
    <div className="send-load-btn-wrapper">
      <Link to="/send-money" className="send-load-btn-wrapper">
        {' '}
        <div className="send-load-icon-wrapper">
          <AppIcon
            name="fa.refresh"
            style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
          />{' '}
        </div>
        Send money
      </Link>
    </div>
  );

  function handleExchangeButtonPressed() {
    setModalVisible(true);
  }

  const { balances } = paymentDevice;
  if (balances) {
    const mainBalance = balances.find(x => x.isMain);

    let orderedBalances = [];
    const shouldMoveMain = balances.length % 2 !== 0;

    if (shouldMoveMain) {
      const length = balances.length;
      const position = Math.ceil(length / 2) - 1;
      const withoutMain = balances.filter(x => !x.isMain);

      withoutMain.splice(position, 0, mainBalance);

      orderedBalances = [...withoutMain];
    } else {
      orderedBalances = balances;
    }
  }

  return (
    <div className="container">
      <div className="dashboard-header">
        <div className="balance-holder">
          <div className="total-holder">
            <LPOverridable name="dashboard.additionalBalance" />
            {OverrideService.getConfig().multiCurrency ? (
              <div className="totalBalances container">
                <div className="row">
                  {orderedBalances.map(balance => {
                    return (
                      <div key={balance.id} className="col-md-4">
                        <LPOverridable
                          key={balance.id}
                          name="dashboard.totalBalance"
                          balance={currency(balance.balance, balance.currency)}
                        >
                          <div className="total-item-holder">
                            {/* <h3>{balance.isMain ? __ov('TOTAL BALANCE') : balance.currency + ' ' + __ov('Balance')}</h3> */}
                            <h3>{`${balance.currency} ${__ov('Balance')}`}</h3>
                            <h1>
                              {currency(balance.balance, balance.currency)}
                            </h1>
                          </div>
                        </LPOverridable>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <LPOverridable
                name="dashboard.totalBalance"
                balance={currency(
                  paymentDevice.balance,
                  paymentDevice.currency
                )}
              >
                <div className="total-item-holder">
                  <h3>{__ov('TOTAL BALANCE')}</h3>
                  <h1>
                    {currency(paymentDevice.balance, paymentDevice.currency)}
                  </h1>
                </div>
              </LPOverridable>
            )}
          </div>
        </div>
        <LPOverridable name="progressbar.content" percent={percentage}>
          <AppProgressBar percent={percentage} />
        </LPOverridable>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            width: '100%',
            maxWidth: 600,
            paddingTop: 10
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              alignItems: 'flex-start'
            }}
          >
            {OverrideService.getConfig().multiCurrency === true && (
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  alignItems: 'flex-start'
                }}
                onClick={() => handleExchangeButtonPressed()}
              >
                <img
                  src={require('../../../assets/currency-euro-dollar-exchange.svg')}
                  alt="Exchange office"
                  style={{ paddingRight: 10 }}
                  onClick={() => handleExchangeButtonPressed()}
                />
                <p className="progress-text">Exchange</p>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              flex: 1
            }}
          >
            <p className="progress-text">{__ov('SPENT TODAY')}</p>
            <p>{currency(profile.spentToday, paymentDevice.currency)}</p>
          </div>
        </div>
        <div
          className="send-load-btn-holder"
          style={{
            opacity: cardActive ? 1 : 0.2,
            pointerEvents: cardActive ? 'auto' : 'none',
            flex: 1
          }}
        >
          <LPOverridable name="card.action.buttons" card={paymentDevice}>
            {loadAccount}
            {sendMoney}
          </LPOverridable>
        </div>
      </div>
      {balances && (
        <FXRatesModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(DashbaoardHeader);
